<template>
  <div class="powered_by__main z-50 sticky bottom-0">
    <div class="powered_by__inner w-full py-5 px-5 md:px-10">
      <a class="powered_by__link block w-full md:flex" href="https://www.patientpoint.com">
        <div class="powered_by__logo w-full text-right md:flex-grow md:order-2">
           <img :src="image" class="footer_logo inline-block" alt="Content powered by PatientPoint" />
        </div>
        <span class="powered_by__copyright block w-full mt-4 text-right md:text-left md:mt-0 md:flex-grow md:order-1" v-html="`©2018-${new Date().getFullYear()} PatientPoint, LLC | About PatientPoint`"></span>
      </a>
    </div>
  </div>
</template>

<script >

import image from '../assets/img/PatientPoint-Logo-NM-2024-264x80.jpg';

export default {
  data(){
    return{
      image: image
    }
  },
}
 
</script>

<style scoped>
.powered_by__main {
  @apply text-pp-gray-light;
  @apply bg-white;
  @apply text-sm;
  /* The TailwindCSS shadow was not applying. */
  box-shadow: 0 0 15px rgb(0, 0, 0, 0.2);
}

.powered_by__inner {
  @apply justify-center;
}

.powered_by__copyright {
  @apply text-xs;
  @apply underline;
  margin-top: 0.5rem;
}

.footer_logo {
  width:150px;
}

</style>
