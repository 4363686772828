import axios from 'axios'

export default ({ nodeID }) => {
  // TODO: Replace this endpoint with the middleware.
  const apiBaseUrl = 'https://content.patientpoint.com'

  const requestUrl = `${apiBaseUrl}/get-content-library-component-properties-by-node/${nodeID}`

  if (process.env.NODE_ENV === 'development') {
    console.log('apiPropertyFetcher requestUrl: ', requestUrl)
  }

  // We need a copy of Axios that doesn't have the general authentication credentials.
  const contentWarehouseAxios = axios.create({
    'headers': null
  })

  return contentWarehouseAxios
    .get(requestUrl)
    .then(({ data }) => {
      return data
    })
    .then(({ success, data }) => {
      let properties = null

      if (success === true) {
        properties = data
      }

      return properties
    })
    .catch((error) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('apiPropertyFetcher error: ', error)
      }
    })
}
