import { createApp } from 'vue'
import App from './App.vue'
// Axios is used to fetch data.
import axios from 'axios'
import VueAxios from 'vue-axios'

// To parse the query strings
import url from 'url'

import apiPropertyFetcher from './apiPropertyFetcher'

// These styles load Tailwind CSS styles.
import './assets/styles/index.css'

// This pattern has been modified so we can alter the routes.
import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './router'

// See `public/index.html` for an example of the embeddable code.
const appID = 'content-library'
const appElement = document.getElementById(appID)

async function authenticate() {
  const tokenUrl = process.env.NODE_ENV === 'production' ? 'https://education.patientpoint.com/webshare/authorization/html/get-token.php' : 'http://localhost/get-token.php'

  await axios.get(tokenUrl)
    .then(({ data }) => {
      return data
    })
    .then(({ success, data }) => {
      if (success === true && typeof data.token !== 'undefined') {
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
      }

      return data
    })
    .catch((error) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('error: ', error)
      }
    })
}

async function getHomeRoute() {
  let homeRoute = {
    path: '/',
    name: 'Home',
  }

  // Setting the route using query parameters:
  // Node ID: Pass `cl_type=node` and `cl_item={node id}`
  // Node IDs: Pass `cl_type=prescribed` and `cl_items={node id 1}|{node id 2}|...`
  // Type and UUID: Pass `cl_type={content library type}` and `cl_uuid`
  const initialQuery = url.parse(window.location.href, true).query

  if (typeof initialQuery.cl_type === 'undefined') {
    homeRoute.component = () => import('@/components/ContentCollection.vue')
    homeRoute.props = {
      uuid: appElement.getAttribute('data-id'),
    }
  }
  else if (initialQuery.cl_type === 'prescribed' && typeof initialQuery.cl_items !== 'undefined') {
    let items = []

    await Promise
      .all(
        initialQuery.cl_items.split('|').map(( nodeID ) => {
          return apiPropertyFetcher({ nodeID })
        })
      )
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('error: ', error)
        }
      })
      .then(( data ) => {
        items = data
          .filter((element) => typeof element === 'object')
          .map(({ uuid, contentLibraryType }) => {
            return {
              uuid,
              type: contentLibraryType,
            }
          })

        return data
      })

    if (items.length > 0) {
      homeRoute.component = () => import('@/components/ContentList.vue')
      homeRoute.props = {
        items,
      }
    }
  }
  else if (initialQuery.cl_type === 'node' && typeof initialQuery.cl_item !== 'undefined') {
    await apiPropertyFetcher({ nodeID: initialQuery.cl_item })
      .then( ({ uuid, contentLibraryType }) => {
        // Determine the component from the routes.
        routes.forEach((route) => {
          if (route.path.split('/')[1] === contentLibraryType) {
            homeRoute.component = route.component
          }
        })

        homeRoute.props = {
          uuid,
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('error: ', error)
        }
      })
  }
  // The use case where a type matches the Content Library type and a UUID is provided.
  else if (typeof initialQuery.cl_uuid !== 'undefined') {
    let uuid = initialQuery.cl_uuid
    let type = initialQuery.cl_type

    // Determine the component from the routes.
    routes.forEach((route) => {
      if (route.path.split('/')[1] === type) {
        homeRoute.component = route.component
      }
    })

    homeRoute.props = {
      uuid: uuid,
    }
  }
  
  if (typeof homeRoute.component === 'undefined') {
    homeRoute.component = () => import('@/components/ErrorMessage.vue')
    homeRoute.props = {
      message: 'Content could not be found.',
    }
  }

  return homeRoute
}

// We are using promises to parse the various entrypoints, but we need to wait on those in order to create the application.
const initializeApp = async () => {
  await authenticate()
  routes.push(await getHomeRoute())

  const router = createRouter({
    history: createWebHashHistory(),
    routes
  })

  // If we want to pass properties to the application, such as a theme class, we can do so here.
  const rootProperties = {}

  createApp(App, rootProperties)
    .use(router)
    .use(VueAxios, axios, router)
    .mount(`#${appID}`)
}

initializeApp()
